
<template>
  <div>
    <v-app-bar
      flat
      color="rgb(53, 80, 123)"
      class="h-10"
    >
      <div
        class="titulo-pagina"
      >
        <span style="color: white;">
          Não há permissão para acessar esta página.
        </span>
      </div>
    </v-app-bar>
    <v-row>
      <span
        class="mx-auto my-12">Navegue para outra página ou contate seu administrador.</span>
    </v-row>
  </div>
</template>

<script>

export default{
  name: 'erropermissao',

}</script>
